/* Ensure the modal appears above other elements */
.swal2-popup-custom {
    z-index: 11112 !important;
    border-radius: 15px !important; /* Rounded corners for the modal */
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2) !important; /* Soft shadow */
    padding: 2rem !important; /* More padding for a spacious look */
  }
  
  .swal2-container-custom {
    z-index: 11112 !important;
  }
  
  /* Style the confirmation button */
  .swal2-confirm-button-custom {
    background-color: var(--primaryColor) !important; /* Use your app's primary color */
    color: white !important; /* White text */
    border: none !important;
    border-radius: 25px !important; /* Rounded button */
    padding: 10px 30px !important; /* Larger padding */
    font-size: 16px !important;
    font-weight: 600 !important; /* Bold text */
    cursor: pointer !important;
    transition: background-color 0.3s ease, transform 0.2s ease !important;
  }
  
  .swal2-confirm-button-custom:hover {
   /* Darker shade on hover */
    transform: scale(1.05) !important; /* Slight scale effect on hover */
  }
  
  .swal2-confirm-button-custom:active {
    transform: scale(0.95) !important; /* Pressed effect */
  }
  
  /* Style the close button (X) */
  .swal2-close-button-custom {
    color: #666 !important; /* Neutral gray color */
    font-size: 24px !important; /* Larger size */
    font-weight: bold !important;
    transition: color 0.3s ease, transform 0.2s ease !important;
  }
  
  .swal2-close-button-custom:hover {
    color: #333 !important; /* Darker gray on hover */
    transform: rotate(90deg) !important; /* Rotate effect on hover */
  }
  
  /* Style the modal title */
  .swal2-title-custom {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #333 !important; /* Dark text for better readability */
    margin-bottom: 1rem !important;
  }
  
  /* Style the modal text */
  .swal2-text-custom {
    font-size: 16px !important;
    color: #555 !important; /* Slightly lighter text */
    line-height: 1.5 !important; /* Better line spacing */
  }
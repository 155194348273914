@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


@font-face {
  font-family: 'QuicksandBold';
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4.woff2') format('woff2');
}
  @font-face {
    font-family: 'QuickSand';
    /* src: url("./assets/fonts/static/Quicksand-Regular.ttf"); */
    /* src: url("./assets/fonts/static/Roboto-Regular.ttf"); */
    font-weight: 300;
    src: local('Roboto'), local('Roboto-Regular'), url('https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2');
}
  @font-face {
    font-family: 'QuickSandMedium';
    /* src: url("./assets/fonts/static/Quicksand-Medium.ttf"); */
    /* src: url("./assets/fonts/static/Roboto-Medium.ttf"); */
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'), url('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBBc4.woff2') format('woff2');
}
  @font-face {
    font-family: 'QuickSandLight';
    /* src: url("./assets/fonts/static/Quicksand-Light.ttf"); */
    /* src: url("./assets/fonts/static/Roboto-Light.ttf"); */
    font-weight: 100;
    src: local('Roboto Light'), local('Roboto-Light'), url('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc4.woff2') format('woff2');
}
  body {
    background: #fbfbfb;
    margin: 0;
    font-family: 'QuicksandBold', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  p{
    font-family: "QuickSand";
    line-height: normal;
    margin: 0 ! important;
  }
  
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  